<template>
  <div class="default-checkbox">
    <label class="checkmark-container">
      <input
        @change="updateValue($event.target.checked)"
        :checked="value"
        :disabled="disabled"
        type="checkbox"
      />
      <span class="checkmark" :class="{ disabled: disabled }"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "DefaultCheckBox",
  props: ["value", "disabled"],
  data() {
    return {};
  },
  computed: {},
  methods: {
    updateValue: function (value) {
      this.$emit("input", value);
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.checkmark-container {
  display: block;
  position: relative;
  margin: 0 auto;
  width: 16px;
  height: 16px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkmark-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid $blizzard;
  border-radius: 4px;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;

  &.disabled {
    border: 1px solid $fog;
  }
}

/* On mouse-over, add a grey background color */
.checkmark-container:hover input ~ .checkmark {
  background-color: transparent;
  border: 1px solid $midnight;
  transition: all ease 0.3s;

  &.disabled {
    border: 1px solid $blizzard;
  }
}

/* When the checkbox is checked, add a blue background */
.checkmark-container input:checked ~ .checkmark {
  background-color: $twilight;
  border: 1px solid $twilight;
  -webkit-transition: all ease 1s;
  transition: all ease 0.5s;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  -webkit-transition: all ease 1s;
  transition: all ease 1s;
}

/* Show the checkmark when checked */
.checkmark-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark-container .checkmark:after {
  left: 4px;
  top: 1px;
  width: 6px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .checkmark-container {
    width: 24px;
    height: 24px;
  }

  /* Create a custom checkbox */
  .checkmark-container .checkmark {
    height: 24px;
    width: 24px;
  }
  .checkmark-container .checkmark:after {
    left: 7px;
    top: 2px;
    width: 9px;
    height: 14px;
    border-width: 0 3px 3px 0;
  }
}
</style>
